import React, { useState } from "react";
import Axios from "axios";
import "./CardSearch.css";
import MagicCard from "../MagicCard/MagicCard";
import SearchBar from "../CardSearchBar/SearchBar";
import logo from "../Assets/logo.png";
import { API_ROUTE } from "../constants";

function CardSearch() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);

  function openCardURL(l) {
    var win = window.open(l, "_blank");
    if (win != null) {
      win.focus();
    }
    console.log("URL: ", l);
  }

  function createCardComponent(cardData) {
    if (cardData.imageUrl == null || cardData.Name) {
    } else {
      return (
        <div class="SmCard">
          <MagicCard
            card={cardData}
            openCardURL={openCardURL}
            displayCard={cardData}
          />
        </div>
      );
    }
  }
  const cardComponents = cards.map(createCardComponent); // map method goes through each element in the array and takes a function (createCardComponent)

  // API call https://docs.magicthegathering.io/
  async function getCards(searchText) {
    setLoading(true);
    console.log("searchText: ", searchText);
    const response = await Axios.get(API_ROUTE + "/cards?name=" + searchText);
    const responseCards = response.data.cards;
    console.log("response: ", responseCards);
    setCards(responseCards); // cards = responseCards, but lets our component know we did that
    setLoading(false);
  }

  let content;

  if (loading == true) {
    content = (
      <div className="CardSearchLoading">
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Loading...
      </div>
    );
  } else {
    content = <div className="SearchCards">{cardComponents}</div>;
  }

  return (
    <div className="CardSearch">
      <div className="SearchBar">
        <SearchBar getCards={getCards} />
      </div>
      <div>{content}</div>
    </div>
  );
}

export default CardSearch;
