import React from "react";
import "./JmpTheme.css";
import {
  AboveTheClouds,
  Angels,
  Archaeology,
  Basri,
  Cats,
  Chandra,
  Devilish,
  Dinosaurs,
  Discarding,
  Doctor,
  Dogs,
  Dragons,
  Elves,
  Enchanted,
  FeatheredFriends,
  Garruk,
  Goblins,
  HeavilyArmored,
  Lands,
  Legion,
  Lightning,
  Liliana,
  Milling,
  Minions,
  Minotaurs,
  Phyrexian,
  Pirates,
  PlusOne,
  Plusone,
  Predatory,
  Rainbow,
  Reanimated,
  Rogues,
  Seismic,
  Smashing,
  Spellcasting,
  Spirits,
  Spooky,
  Teferi,
  TreeHugging,
  UnderTheSea,
  Unicorns,
  Vampires,
  Walls,
  WellRead,
  Witchcraft,
  Wizards,
} from "../../Assets/Themes/themes";

const themeImages = {
  AboveTheClouds,
  Angels,
  Archaeology,
  Basri,
  Cats,
  Chandra,
  Devilish,
  Dinosaurs,
  Discarding,
  Doctor,
  Dogs,
  Dragons,
  Elves,
  Enchanted,
  FeatheredFriends,
  Garruk,
  Goblins,
  HeavilyArmored,
  Lands,
  Legion,
  Lightning,
  Liliana,
  Milling,
  Minions,
  Minotaurs,
  Phyrexian,
  Pirates,
  PlusOne,
  Plusone,
  Predatory,
  Rainbow,
  Reanimated,
  Rogues,
  Seismic,
  Smashing,
  Spellcasting,
  Spirits,
  Spooky,
  Teferi,
  TreeHugging,
  UnderTheSea,
  Unicorns,
  Vampires,
  Walls,
  WellRead,
  Witchcraft,
  Wizards,
};

function JmpTheme({ imageKey, themeId, selectTheme, border, selected }) {
  //const [selection, saveSelection] = useState([]);

  function handleClick() {
    selectTheme(themeId);
    console.log("CLICKED ", themeId, "Selected: ", border);
  }

  //*BUG* UndertheSea vs UnderTheSea and Plusone vs PlusOne
  return (
    <img
      onClick={handleClick}
      className={`ThemeImage ${
        border == true && selected.includes(themeId) ? "Selected" : ""
      }`}
      src={themeImages[imageKey]}
      alt={imageKey}
    />
  );
}

export default JmpTheme;
