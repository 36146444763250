import React from "react";
import image from "../Assets/mana.png";
import "./Header.css";
import { RiMapPinUserLine } from "react-icons/ri";
import { Link, useLocation } from "@reach/router";

function Header() {
  const { pathname } = useLocation();

  return (
    <div className="Header">
      <div>
        <Link to="/ProfilePage">
          <RiMapPinUserLine
            className={`HeaderIcon ${
              pathname == "/ProfilePage" ? "Active" : ""
            }`}
          />
        </Link>
      </div>
      <div>
        <img className="HeaderImage" src={image} />
      </div>
      {/* <FontAwesome class="far fa-user" /> */}
    </div>
  );
}

export default Header;
