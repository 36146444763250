import React, { useEffect, useState } from "react";
import logo from "../Assets/logo.png";
import "./ProfilePage.css";
import Axios from "axios";
import { API_ROUTE_2 } from "../constants.js";
import JmpDeck from "../Jmp/JmpDeck/JmpDeck.js";

function ProfilePage() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [navState, setNavState] = useState(1);
  const [selected, setSelected] = useState([]);
  const [cards, setCards] = useState([]);

  // Get Themes From Saved Decks on Initialization
  let cardList;
  useEffect(() => {
    // Runs on first render and never again because the array is empty
    setSelected(window.localStorage.getItem("MyDeck").split(","));
  }, []);

  useEffect(() => {
    // Runs any time selected changes
    (async () => {
      // **IIFE Async useEffect
      if (selected.length == 2) {
        cardList = await getCards(selected);
        console.log("selected: ", selected);
        console.log("cardList: ", cardList);
      }
    })();
  }, [selected]);

  // Get Cards From Selected Decks
  async function getCards(t) {
    console.log("calling get cards");
    setLoading(true);
    try {
      const response = await Axios.get(
        API_ROUTE_2 +
          "/deck?firstDecklistId=" +
          t[0] +
          "&secondDecklistId=" +
          t[1]
      );
      const responseCards = response.data;
      setCards(responseCards);
      console.log(
        "response: ",
        response,
        "responseCards: ",
        responseCards,
        " cards: ",
        cards
      );
      setLoading(false);
      return responseCards;
    } catch (error) {
      setHasError(true);
      setLoading(false);
      console.log("We couldn't get your deck: ", error);
    }
  }

  // Render Content
  let content;
  if (loading == true) {
    content = (
      <div>
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Loading...
      </div>
    );
  } else {
    if (hasError == true) {
      content = <div>Oh No! You Jumpstopped!</div>;
    }
    if (navState == 1) {
      content = (
        <div className="ProfilePageContent">
          <div className="ProfilePageItem AppText">
            <div className="ProfilePageItemTitle">Name:</div>
            <div className="ProfilePageItemInfo">Grundwich Chimpywimps</div>
          </div>
          <div className="ProfilePageItem AppText">
            <div className="ProfilePageItemTitle">Gender Pronoun:</div>
            <div className="ProfilePageItemInfo">Him/He</div>
          </div>
          <div className="ProfilePageItem AppText">
            <div className="ProfilePageItemTitle">Playstyle:</div>
            <div className="ProfilePageItemInfo">Control</div>
          </div>
          <div className="ProfilePageItem AppText">
            <div className="ProfilePageItemTitle">Eybrow Color:</div>
            <div className="ProfilePageItemInfo">Light Brown</div>
          </div>
          <div className="ProfilePageItem AppText">
            <div className="ProfilePageItemTitle">Number of toes:</div>
            <div className="ProfilePageItemInfo">10</div>
          </div>
        </div>
      );
    }
    if (navState == 2) {
      content = <JmpDeck cards={cards} />;
    }
  }

  return (
    <div className="ProfilePage">
      <div className="ProfilePageTitle AppTitle">User Information</div>
      {content}
    </div>
  );
}

export default ProfilePage;
