import React from "react";
import "./MagicCard.css";

function MagicCard({ card, count, openCardURL, displayCard }) {
  console.log("card: ", card);

  function handleClick() {
    let u = card.imageUrl
      .substring(0, card.imageUrl.indexOf("&"))
      .split("=")[1];
    let link;
    if (u != null) {
      link =
        "https://gatherer.wizards.com/Pages/Card/Details.aspx?multiverseid=" +
        u;
    }
    openCardURL(link);
  }

  if (count > 0) {
    return (
      <img
        className={`Card ${displayCard == card ? "ShowCard" : "NoCard"}`}
        src={card.imageUrl}
        alt={card.name}
      />
    );
  } else {
    return (
      <img
        className={`Card ${displayCard == card ? "ShowCard" : "NoCard"}`}
        src={card.imageUrl}
        alt={card.name}
        onClick={handleClick}
      />
    );
  }
}

export default MagicCard;
