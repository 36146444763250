import React, { useState } from "react";
import "./JmpDeck.css";
import MagicCard from "../../MagicCard/MagicCard";

function JmpDeck({ cards = [] }) {
  const [displayCard, setDisplayCard] = useState();
  const [navState, setNavState] = useState(1);

  // Menu navigation
  function menuCardList() {
    setNavState(1);
  }

  function menuShowAll() {
    setNavState(2);
  }

  // List out cards, and allow them to be clicked and rendered
  console.log("Cards: ", cards);
  function listCards({ count, card }) {
    console.log("card: ", toString(card.name));

    function handleClick() {
      setDisplayCard(card);
    }

    return (
      <div className="CardName" onClick={handleClick}>
        {count} {card.name}
      </div>
    );
  }

  // CARD LIST : Card images are all rendered on load, but only displayed when displayCard is populated
  // Order Cards by name, and count
  const cardList = cards
    .sort(function (a, b) {
      var nameA = a.card.name;
      var nameB = b.card.name;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    })
    .sort(function (a, b) {
      return b.count - a.count;
    })
    .map(listCards);

  const cardComponentsList = cards.map(createCardComponentList);
  function createCardComponentList({ count, card }) {
    if (card.imageUrl == null || card.Name) {
    } else {
      return (
        <div className="StackedCard">
          <MagicCard card={card} count={count} displayCard={displayCard} />
        </div>
      );
    }
  }

  // SHOW ALL CARDS : Card Images are all shown and can be clicked to navigate to external site
  function createCardComponent({ count, card }) {
    console.log(card);
    if (card.imageUrl == null || card.Name) {
    } else {
      //setDisplayCard(card);
      return (
        <div className="SmCard">
          <MagicCard card={card} count="1" displayCard={card} />
        </div>
      );
    }
  }
  const cardComponents = cards.map(createCardComponent);

  // function openCardURL(l) {
  //   var win = window.open(l, "_blank");
  //   if (win != null) {
  //     win.focus();
  //   }
  //   console.log("URL: ", l);
  // }

  // Conditional rendering of 'List Cards | Show All Cards'
  let content;

  if (navState == 1) {
    content = (
      <div className="JmpDeck">
        <div className="CardList">{cardList}</div>
        <div className="CardImages">
          <div className="LgCard">{cardComponentsList}</div>
        </div>
      </div>
    );
  }
  if (navState == 2) {
    content = (
      <div className="JmpDeck">
        <div className="CardImagesGrid">{cardComponents}</div>
      </div>
    );
  }

  // Render content
  return (
    <div>
      <div className="JmpMenu">
        <div className="JmpMenuItem NavItem" onClick={menuCardList}>
          Card List
        </div>
        |
        <div className="JmpMenuItem NavItem" onClick={menuShowAll}>
          Show All Cards
        </div>
      </div>
      {content}
    </div>
  );
}

export default JmpDeck;
