import React from "react";
import "./SideBar.css";
import { Link, useLocation } from "@reach/router";

function SideBar({ hidden, hideSideBar }) {
  const { pathname } = useLocation();

  function handleClick() {
    if (hidden == false) {
      hideSideBar(true);
    }
    if (hidden == true) {
      hideSideBar(false);
    }
    //hideSideBar(true);
  }

  return (
    <div>
      <div className={`SideBar${hidden == true ? " Hidden" : ""}`}>
        <Link to="/CardSearch">
          <div
            className={`NavItem ${pathname == "/CardSearch" ? "Active" : ""}`}
          >
            Card Search
          </div>
        </Link>
        <Link to="/BoosterPackGenerator">
          <div
            className={`NavItem ${
              pathname == "/BoosterPackGenerator" ? "Active" : ""
            }`}
          >
            Booster Pack Generator
          </div>
        </Link>
        <Link to="/">
          <div className={`NavItem ${pathname == "/" ? "Active" : ""}`}>
            Jumpstart
          </div>
        </Link>
        <button className="HideButton" onClick={handleClick} />
      </div>
    </div>
  );
}

export default SideBar;
