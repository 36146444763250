import React, { useEffect, useState } from "react";
import logo from "../../Assets/logo.png";
import "./JmpUserDecks.css";
import Axios from "axios";
import { API_ROUTE_2 } from "../../constants.js";
import JmpDeck from "../JmpDeck/JmpDeck.js";
import { Link, useLocation } from "@reach/router";
import JmpTheme from "../JmpTheme/JmpTheme.js";

function JumpUserDecks() {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [navState, setNavState] = useState(1);
  const [selected, setSelected] = useState([]);
  const [cards, setCards] = useState([]);
  const [themes, setThemes] = useState([]);

  // Get Themes From Saved Decks on Initialization
  let cardList;
  useEffect(() => {
    // Runs on first render and never again because the array is empty
    setSelected(window.localStorage.getItem("MyDeck").split(","));
  }, []);

  useEffect(() => {
    // Runs any time selected changes
    (async () => {
      // **IIFE Async useEffect
      if (selected.length == 2) {
        getThemes(selected);
        //cardList = await getCards(selected);

        console.log("selected1: ", selected[0]);
        console.log("selected2: ", selected[1]);
        console.log("cardList: ", cardList);
        console.log("themeList: ", themeList);
      }
    })();
  }, [selected]);

  console.log("selected: ", selected);

  let themeList = createThemeComponent(themes);

  // Get Theme Images
  async function getThemes(selected) {
    setLoading(true);
    try {
      const response = await Axios.get(API_ROUTE_2 + "/decklists/"); //"/deck?firstDecklistId=[0].Id&deck?secondDecklistId=[1].Id"
      /// ** Need correct api patthway
      const responseThemes = response.data.map((theme) => {
        theme.name = theme.name.replace(/[^A-Za-z]/g, "");
        return theme;
      });
      console.log("response: ", response, "responseThemes: ", responseThemes);
      setThemes(responseThemes); // cards = responseCards, but lets our component know we did that
    } catch (error) {
      setHasError(true);
      console.log("Please try another set Error: ", error);
    }
    setLoading(false);
  }

  // Create Theme Components
  function createThemeComponent(t) {
    return (
      <div>
        <JmpTheme
          imageKey={t.imageKey}
          themeId={t.id}
          // selectTheme={selectTheme}
          // border={border}
          selected={selected}
        />
      </div>
    );
  }
  const themeComponents = themes.map(createThemeComponent);

  // ClickHandlers
  function handleClickBack() {
    setHasError(false);
  }

  // Render Content
  let content;
  if (loading == true) {
    content = (
      <div>
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Loading...
      </div>
    );
  } else {
    if (hasError == true) {
      content = <div>Oh No! You Jumpstopped!</div>;
    }
    if (navState == 1) {
      content = (
        <div>
          <div className="JmpUserDecks AppTitle">My Jumpstart Decks</div>
          {themeComponents}
          <JmpDeck cards={cards} />
        </div>
      );
    }
    if (navState == 2) {
      content = <JmpDeck cards={cards} />;
    }
  }

  return (
    <div className="JmpUserDeckPage">
      <div>{content}</div>
      <div class="NavButtons">
        <button className="Button" onClick={handleClickBack}>
          Back
        </button>
      </div>
    </div>
  );
}

export default JumpUserDecks;
