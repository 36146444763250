import React, { useState } from "react";
import logo from "../../Assets/logo.png";
import Image from "../../Assets/JumpStartImage.jpg";
import Axios from "axios";
import "./JmpApp.css";
import JmpChoice from "../JmpChoice/JmpChoice";
import JmpDeck from "../JmpDeck/JmpDeck";
import { API_ROUTE_2 } from "../../constants";
import { Link, useLocation } from "@reach/router";

function JmpApp() {
  const [themes, setThemes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [navState, setNavState] = useState(1);
  const [selected, setSelected] = useState([]);
  const [cards, setCards] = useState([]);
  const { pathname } = useLocation();

  function handleClickRestart() {
    setHasError(false);
    setNavState(1);
  }

  function handleClickNext() {
    if (navState == 1) {
      getThemes();
    }
    if (navState == 2) {
      getCards(selected);
    }
    if (navState == 3) {
      saveDeck(selected);
    }
    setHasError(false);
    setNavState(navState + 1);
  }

  // Get 3 themes and present choice
  async function getThemes() {
    setLoading(true);
    try {
      const response = await Axios.get(API_ROUTE_2 + "/decklists/random"); //"/deck?firstDecklistId=[0].Id&deck?secondDecklistId=[1].Id"
      const responseThemes = response.data.map((theme) => {
        theme.name = theme.name.replace(/[^A-Za-z]/g, "");
        return theme;
      });
      console.log("response: ", response, "responseThemes: ", responseThemes);
      setThemes(responseThemes); // cards = responseCards, but lets our component know we did that
    } catch (error) {
      setHasError(true);
      console.log("Please try another set Error: ", error);
    }
    setLoading(false);
  }

  // Get Cards From Selected Decks
  async function getCards(t) {
    setLoading(true);
    try {
      const response = await Axios.get(
        API_ROUTE_2 +
          "/deck?firstDecklistId=" +
          selected[0] +
          "&secondDecklistId=" +
          selected[1]
      );
      const responseCards = response.data;
      console.log("responseCards: ", responseCards);
      setCards(responseCards.cards);
    } catch (error) {
      setHasError(true);
      console.log("Please try another set Error: ", error);
    }
    setLoading(false);
  }

  // Save deck to profile
  function saveDeck(s) {
    console.log("saveDeck:" + s);
    window.localStorage.setItem("MyDeck", s);
  }

  // Render Content
  let content;
  if (loading == true) {
    content = (
      <div>
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Loading...
      </div>
    );
  } else {
    if (hasError == true) {
      content = <div>Oh No! You Jumpstopped!</div>;
    } else {
      if (navState == 1) {
        content = (
          <div>
            <div className="JmpMenu">
              <div className="JmpMenuItem NavItem">Play Jumpstart</div> |
              <Link to="/Jumpstart/Decks/">
                <div
                  className="JmpMenuItem NavItem"
                  pathname="/Jumpstart/Decks/"
                >
                  My Decks
                </div>
              </Link>
              |
              <div className="JmpMenuItem NavItem" onClick={handleClickNext}>
                Pick Themes
              </div>
            </div>
            <img className="JmpImage" src={Image} /> <br />
          </div>
        );
      } else if (navState == 2) {
        content = (
          <div>
            Choose 2 themes to continue...
            <div>
              <JmpChoice
                themes={themes}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
            <div className="NavButtons">
              <button className="Button" onClick={handleClickRestart}>
                Restart
              </button>
              <button className="Button" onClick={handleClickNext}>
                Next
              </button>
            </div>
          </div>
        );
      } else if (navState == 3) {
        content = (
          <div>
            <div>
              <JmpDeck cards={cards} />
            </div>
            <div class="NavButtons">
              <button className="Button" onClick={handleClickRestart}>
                Restart
              </button>
              <button className="Button" onClick={handleClickNext}>
                Save Deck
              </button>
            </div>
          </div>
        );
      } else if (navState == 4) {
        setNavState(1);
        //saveDeck();
      }
    }
  }

  return <div className="JumpstartGenerator">{content}</div>;
}

export default JmpApp;
