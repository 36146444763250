import React, { useState } from "react";
import logo from "../../Assets/logo.png";
import "./JmpChoice.css";
import JmpTheme from "../JmpTheme/JmpTheme";

function JmpChoice({ themes, selected, setSelected }) {
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [navState, setNavState] = useState(1);
  const [border, setBorder] = useState(false);

  function selectTheme(t) {
    if (!selected.includes(t)) {
      setSelected(selected.concat(t).slice(-2)); // *HW* copy array, get last 2
      setBorder(true);
    }
    console.log("t: ", t, "Selected: ", selected);

    return selected;
  }

  console.log("JMPChoiceThemes: ", themes);

  // Create Theme
  function createThemeComponent(t) {
    return (
      <div>
        <JmpTheme
          imageKey={t.imageKey}
          themeId={t.id}
          selectTheme={selectTheme}
          border={border}
          selected={selected}
        />
      </div>
    );
  }
  const themeComponents = themes.map(createThemeComponent); // map method goes through each element in the array and takes a function (createCardComponent)

  // Generate Page Content
  let content;
  if (loading == true) {
    content = (
      <div>
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Loading..
      </div>
    );
  } else {
    if (hasError == true) {
      content = <div>Oh No! You Jumpstopped!</div>;
    } else {
      if (navState == 1) {
        content = <div className="CardThemes">{themeComponents}</div>;
      }
    }
  }

  return <div>{content}</div>;
}

export default JmpChoice;
