import React, { useState } from "react";
import "./SearchBar.css";

function SearchBar({ getCards }) {
  //const inputTextState = useState("");
  //const inputText = inputTextState[0];
  //const setInputText = inputTextState[1];
  const [inputText, setInputText] = useState([]);

  function handleInput(event) {
    setInputText(event.target.value);
  }

  function handleClick() {
    getCards(inputText);
  }

  return (
    <div>
      <input
        className="Input"
        placeholder="Enter Text"
        value={inputText}
        onChange={handleInput}
      />
      <button className="Button" onClick={handleClick}>
        Search
      </button>
    </div>
  );
}

export default SearchBar;
