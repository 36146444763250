import React, { useState, useEffect } from "react";
import logo from "../Assets/logo.png";
import Axios from "axios";
import "./BoosterPackGenerator.css";
import MagicCard from "../MagicCard/MagicCard";
import { API_ROUTE } from "../constants";

function BoosterPackGenerator() {
  const [sets, setSets] = useState([]);
  const [setCode, setSet] = useState([]);
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSet, setLoadingSet] = useState(false);
  const [hasError, setHasError] = useState(false);

  function handleClick() {
    setHasError(false);
    getCards();
  }

  function createCardComponent(cardData) {
    if (cardData.imageUrl == null || cardData.Name) {
    } else {
      return (
        <div class="SmCard">
          <MagicCard card={cardData} displayCard={cardData} />
        </div>
      );
    }
  }
  const cardComponents = cards.map(createCardComponent); // map method goes through each element in the array and takes a function (createCardComponent)

  // API call https://docs.magicthegathering.io/
  async function getCards() {
    setLoading(true);
    try {
      const responseBooster = await Axios.get(
        API_ROUTE + "/sets/" + setCode + "/booster"
      );
      const responseBoosterCards = responseBooster.data.cards;
      console.log("responseBoosterCards: ", responseBoosterCards);
      setCards(responseBoosterCards); // cards = responseBoosterCards, but lets our component know we did that
    } catch (error) {
      setHasError(true);
      console.log("Please try another set Error: ", error);
    }
    setLoading(false);
  }

  // API call https://docs.magicthegathering.io/
  async function getOptions() {
    setLoadingSet(true);
    const responseSet = await Axios.get(API_ROUTE + "/sets");
    console.log("Sets: ", responseSet.data.sets);
    const responseSets = responseSet.data.sets.filter((s) => s.booster);
    setSets(responseSets); // cards = responseBoosterCards, but lets our component know we did that
    setLoadingSet(false);
    return responseSets;
  }

  useEffect(() => {
    getOptions();
  }, []);

  function handleSetSelection(e) {
    console.log("SetSelection: ", e.target.value);
    setSet(e.target.value);
  }

  function createSelectItems() {
    let items = [];
    for (let set of sets) {
      items.push(
        <option key={set.code} value={set.code}>
          {set.name}
        </option>
      );
      //here I will be creating my options dynamically based on
      //what props are currently passed to the parent component
    }
    return items;
  }

  // function onDropdownSelected(e) {
  //   console.log("THE VAL", e.target.value);
  //   //here you will see the current selected value of the select input
  // }

  let content;
  if (loading == true) {
    content = (
      <div className="BoosterPackLoading">
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Loading...
      </div>
    );
  } else if (loadingSet == true) {
    content = (
      <div className="BoosterPackLoading">
        <img src={logo} className="App-logo" alt="logo" />
        <br />
        Getting Set Choices...
      </div>
    );
  } else {
    if (hasError == true) {
      content = <div>Please try another set.</div>;
    } else {
      content = <div className="BoosterPackCards">{cardComponents}</div>;
    }
  }

  return (
    <div className="BoosterPackGenerator">
      <div className="BoosterPackSelector">
        <select
          className="SetInput"
          onChange={handleSetSelection}
          label="Multiple Select"
        >
          {createSelectItems()}
        </select>
        <button className="GenerateBooster Button" onClick={handleClick}>
          Generate
        </button>
      </div>
      <div>{content}</div>
    </div>
  );
}

export default BoosterPackGenerator;
