import React, { useState } from "react";
import "./App.css";
import { Router, LocationProvider } from "@reach/router";
import Header from "../Header/Header";
import SideBar from "../SideBar/SideBar";
import CardSearch from "../CardSearch/CardSearch";
import BoosterPackGenerator from "../BoosterPackGenerator/BoosterPackGenerator";
import JmpApp from "../Jmp/JmpApp/JmpApp";
import ProfilePage from "../ProfilePage/ProfilePage";
import JmpUserDecks from "../Jmp/JmpUserDecks/JmpUserDecks";

function App() {
  const [hidden, setHidden] = useState(false);

  function hideSideBar(h) {
    setHidden(h);
    console.log("hideSideBar: ", hidden);
  }

  return (
    <LocationProvider>
      <div className="App">
        <Header />
        <div className="App-Main">
          <div className="App-Side">
            <SideBar hidden={hidden} hideSideBar={hideSideBar} />
          </div>
          <div className={`Content ${hidden == true ? "HiddenContent" : ""}`}>
            <Router>
              <CardSearch path="/CardSearch" />
              <BoosterPackGenerator path="/BoosterPackGenerator" />
              <JmpApp path="/" />
              <ProfilePage path="/ProfilePage" />
              <JmpUserDecks path="/Jumpstart/Decks" />
            </Router>
          </div>
        </div>
      </div>
    </LocationProvider>
  );
}

export default App;
